import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <CustomVideoPlayer width="960" loop autoPlay src="https://github.com/user-attachments/assets/ca994321-2080-42da-9b85-881cfd61f096" mdxType="CustomVideoPlayer" />
    <h2>{`Usage`}</h2>
    <p>{`Skeleton text may be used to improve perceived performance by rendering a rough representation of text content that is still loading.`}</p>
    <p>{`However, it is important to avoid using skeleton text to replace interactive elements that are still loading. Instead, hide interactive elements that are still loading until they are ready to be displayed.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/cb52d978-fcb5-41b6-a541-3da0bff7b126" role="presentation" width="456" />
    <Caption mdxType="Caption">Hide interactive elements that are still loading</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/5dd54439-9ca5-48e1-8068-859cccd7b882" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use skeleton text to replace the text in interactive elements that are still loading</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Options`}</h2>
    <h3>{`Sizes`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <p>{`Skeleton text sizes are designed to match the font sizes and line heights defined in Primer. See `}<a parentName="p" {...{
          "href": "/foundations/typography"
        }}>{`typographhy docs`}</a>{` and `}<a parentName="p" {...{
          "href": "https://primer.style/primitives/storybook/?path=/story/typography-functional--display"
        }}>{`Primitives Primitives`}</a>{` for details.`}</p>
      <img src="https://github.com/primer/design/assets/2313998/39b5a0df-fdf3-4e12-9e01-a78d36e749a1" role="presentation" width="456" />
    </Box>
    <h2>{`Acccessibility`}</h2>
    <p>{`Refer to the loading pattern `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#accessibility"
      }}>{`accessibility guidelines`}</a>{`. Specifically, the secion about `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#avoiding-over-announcing-loading-states"
      }}>{`avoiding over-announcing loading states`}</a>{`.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="SkeletonText" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/loading"
        }}>{`Loading`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/typography"
        }}>{`Typography`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-avatar"
        }}>{`Skeleton avatar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-box"
        }}>{`Skeleton box`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      